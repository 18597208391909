import type {FetchOptions} from 'ofetch';
import PostListPostsRepository from '~/repository/post_list_posts_repository';
import PostsRepository from '~/repository/post_repository';
import PostUpdatesRepository from '~/repository/post_updates_repository';
import YoutubeRepository from '~/repository/youtube_repository';
import RealEstateRepository from '~/repository/real_estate_repository';
import BlogRepository from '~/repository/blog_repository';
import OpinionRepository from '~/repository/opinion_repository';
import PollRepository from '~/repository/poll_repository';
import TagRepository from '~/repository/tag_repository';
import PollVoteRepository from '~/repository/poll_vote_repository';
import RssFeedRepository from '~/repository/rss_feed_repository';
import MenuRepository from '~/repository/menu_repository';
import CategoryRepository from '~/repository/category_repository';
import CitiesRepository from '~/repository/cities_repository';
import SearchRepository from '~/repository/search_repository';
import ArchivedPostsRepository from '~/repository/archived_posts_repository';
import LocaleRepository from '~/repository/locale_repository';
import SettingsRepository from "~/repository/settings_respository";

/** ApiInstance interface provides us with good typing */
export interface IRepositoryInstance {
    post_list_posts_repository: PostListPostsRepository,
    post_repository: PostsRepository,
    post_updates_repository: PostUpdatesRepository,
    youtube_repository: YoutubeRepository,
    blog_repository: BlogRepository,
    opinion_repository: OpinionRepository,
    poll_repository: PollRepository
    tag_repository: TagRepository,
    poll_vote_repository: PollVoteRepository
    rss_feed_repository: RssFeedRepository
    menu_repository: MenuRepository,
    categories_repository: CategoryRepository
    cities_repository: CitiesRepository,
    search_repository: SearchRepository
    archived_posts_repository: ArchivedPostsRepository,
    locale_repository: LocaleRepository,
    settings_repository: SettingsRepository,
    real_estate_listings_repository: RealEstateRepository,
}

export const repositories = () => {
    const fetchOptions: FetchOptions = {
        baseURL: useRuntimeConfig().public.apiBaseUrl,
        headers: {
            'Accepts-Locale': 'el',
            'User-Agent': 'Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:129.0) Gecko/20100101 Firefox/129.0',
        },
    };

    /** create a new instance of $fetcher with custom option */
    const apiFetcher = $fetch.create(fetchOptions);

    /** an object containing all repositories we need to expose */
    const repositories: IRepositoryInstance = {
        post_list_posts_repository: new PostListPostsRepository(apiFetcher),
        post_repository: new PostsRepository(apiFetcher),
        post_updates_repository: new PostUpdatesRepository(apiFetcher),
        youtube_repository: new YoutubeRepository(apiFetcher),
        blog_repository: new BlogRepository(apiFetcher),
        opinion_repository: new OpinionRepository(apiFetcher),
        poll_repository: new PollRepository(apiFetcher),
        tag_repository: new TagRepository(apiFetcher),
        poll_vote_repository: new PollVoteRepository(apiFetcher),
        rss_feed_repository: new RssFeedRepository(apiFetcher),
        menu_repository: new MenuRepository(apiFetcher),
        categories_repository: new CategoryRepository(apiFetcher),
        cities_repository: new CitiesRepository(apiFetcher),
        search_repository: new SearchRepository(apiFetcher),
        archived_posts_repository: new ArchivedPostsRepository(apiFetcher),
        locale_repository: new LocaleRepository(apiFetcher),
        settings_repository: new SettingsRepository(apiFetcher),
        real_estate_listings_repository: new RealEstateRepository(apiFetcher)
    };

    return repositories;
};